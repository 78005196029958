import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const metasService = {
    getByUser
};


async function getByUser(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/metas/users/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}
