<script>
import { filesBaseUrl } from '../../constants/config';

/**
 * Transaction component
 */
export default {
    props: {
        showDetail: {
            type: Boolean,
            default: true
        },
        datas: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },
    data() {
        return {
            filesBaseUrl
        }
    },
    methods: {
        showDetails(item) {
            this.$router.push(`/events/transactions/${item.id}/detail`);
        }
    }
};
</script>

<template>
    <div class="table-responsive mb-0">
        <table class="table table-hover table-centered table-nowrap">
            <thead class="bg-primary text-white">
                <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Titre</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>
                    <th v-if="showDetail" scope="col">Montant</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="data in datas" :key="data.id">
                    <th scope="row">
                        <img v-if="data.album != null" width="70" :src="filesBaseUrl+'/album/'+data.album.image" alt="">
                        <img v-if="data.album == null" width="70" :src="filesBaseUrl+'/morceaux/'+data.morceaux.image" alt="">
                    </th>
                    <td>
                        <div>
                            {{ data.album != null ? data.album.libelle : data.morceaux.libelle }}
                        </div>
                    </td>
                    <td>
                        <span class="badge" :class="{
                            'bg-success': true
                        }">
                        {{ data.album == null ? "Morceaux" : "Album" }}
                        </span>
                    </td>
                    <td>{{data.created | defautTableDateTime2 }}</td>
                   
                    <td v-if="showDetail">
                        <span class="badge" :class="{
                            'bg-success': true
                        }">
                        {{ data.album != null ? data.album.amount : data.morceaux.amount | currencyFormat }}
                        </span>
                            
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>