<script>
/**
 * Transaction component
 */
export default {
  props: {
    showDetail: {
      type: Boolean,
      default: true
    },
    transactions: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
     showDetails(item) {
      this.$router.push(`/events/transactions/${item.id}/detail`);
    }
  }
};
</script>

<template>
  <div class="table-responsive mb-0">
    <table class="table table-hover table-centered table-nowrap">
      <thead>
        <tr>
          <th scope="col">Réference</th>
          <th scope="col">Opérateur</th>
          <th scope="col">Date</th>
          <th scope="col">Montant</th>
          <th scope="col">Téléphone</th>
          <th scope="col">Type</th>
          <th scope="col">Etat</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in transactions" :key="data.id">
          <th scope="row">{{data.transactionId }}</th>
          <td>
            <div>
              {{data.operateur}}
            </div>
          </td>
          <td>{{data.dateTransaction | defautTableDateTime2 }}</td>
          <td>
            <span
                  class="badge"
                  :class="{
                    'bg-success': true
                  }"
                >{{ data.montant | currencyFormat }}
              </span>
          </td>
          <td>
            {{ data.number }}
          </td>
          <td>
              <div v-if="data.meta != null">
                
                <span
                    class="badge"
                    :class="{
                      'bg-info': true
                    }"
                  >{{ data.meta.album != null ? "Album" : "Morceaux" }}
                </span>
              </div>
              <div v-if="data.meta == null">
                <span
                    class="badge"
                    :class="{
                      'bg-success': true
                    }"
                  >Rechargement de compte
                </span>
              </div>
            </td>
          <td>
            <span v-if="data.etat == 'SUCCES'"
              class="badge"
              :class="{
                'bg-success': true,
              }"> {{ data.etat }}</span>
              <span v-if="data.etat != 'SUCCES'"
              class="badge"
              :class="{
                'bg-danger': true,
              }"> {{ data.etat }}</span>
          </td>
          <td>
            <div>
              <a @click="showDetails(data)" style="cursor: pointer;" class="btn btn-primary btn-sm">Détail</a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>