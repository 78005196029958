<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { clientsService } from '../../../../services/clients.service';
import { metasService } from '../../../../services/metas.service';
import { transactionServices } from '../../../../services/transaction.service';
import Transaction from "@/components/widgets/transaction";
import Telechargement from "@/components/widgets/telechargement";
import { filesBaseUrl } from "../../../../constants/config";

/**
 * Blank page component
 */
export default {
    page: {
        title: "Détail utilisateur",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Transaction, Telechargement },
    data() {
        return {
            title: "Détail Utilisateur",
            client: null,
            transactions: [],
            filesBaseUrl,
            telechargements: [],
            likes: [],
            ecoutes: []

        };
    },
    mounted() {
        this.getClientDetail();
        this.getMetas();
        this.getTransactions();
    },
    methods: {
        async getClientDetail() {
            const id = this.$router.currentRoute.params.id;
            this.client = await clientsService.getOne(id);
        },
        async getMetas() {
            const metas = await metasService.getByUser(this.$router.currentRoute.params.id);
            this.likes = metas.filter(e => e.typeMeta.name.toLowerCase().includes('like'));
            this.telechargements = metas.filter(e => e.typeMeta.name.toLowerCase().includes('tele'));
            this.ecoutes = metas.filter(e => e.typeMeta.name.toLowerCase().includes('ecou'));
        },
        async getTransactions() {
            this.transactions = await transactionServices.getByUser(this.$router.currentRoute.params.id);
        },
        back() {
            this.$router.back();
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
        <i
          @click="back()"
          style="font-size: 28px; cursor: pointer; margin-bottom: 10px;"
          class="fa fa-arrow-left"
          st
        ></i>
        <div v-if="client != null">
            <div class="row">
                <div class="col-md-12 text-center mb-4">
                    <div class="avatar">
                        <img :src="filesBaseUrl+'/user/'+client.image" width="100" alt="" class="avatar rounded-circle ">
                    </div>
                        <div class="col-md-12 fs-4">
                            {{ client.firstname }} {{ client.lastname }}
                        </div>
                        <div class="col-md-12 fs-4">
                                {{ client.tel }}
                        </div>
                        <div class="col-md-12 fs-4">
                            {{ client.email }}
                        </div>
                        <span
                            class="badge font-size-20"
                            :class="{
                                'bg-success': true,
                            }">  {{ client.compte != null ? client.compte.solde : 0 | currencyFormat }} 
                        </span>
                    </div>
            </div>
              <div class="card">
                      <div class="card-body">
                        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3">

                              <b-tab>
                                <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <!-- <i class="far fa-envelope"></i> -->
                                    Téléchargements
                                  </span>
                                  <span class="d-none d-sm-inline-block">Téléchargements <span class="badge fs-6" :class="{ 'bg-primary': true }"> {{ telechargements.length }} </span> </span>
                      
                                </template>
                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <Telechargement :datas="telechargements" :showDetail="true"/>
                                        </div>
                                    </div>
                                </div>
                          </b-tab>

                          <b-tab>
                                <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <!-- <i class="far fa-envelope"></i> -->
                                    Likes
                                  </span>
                                  <span class="d-none d-sm-inline-block">Likes <span class="badge fs-6" :class="{ 'bg-primary': true }"> {{ likes.length }} </span> </span>
                      
                                </template>
                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <Telechargement :datas="likes" :showDetail="false"/>
                                        </div>
                                    </div>
                                </div>
                          </b-tab>
                        
                          <b-tab>
                                <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <!-- <i class="far fa-envelope"></i> -->
                                    Ecoutes
                                  </span>
                                  <span class="d-none d-sm-inline-block">Ecoutes <span class="badge fs-6" :class="{ 'bg-primary': true }"> {{ ecoutes.length }} </span> </span>
                      
                                </template>
                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <Telechargement :datas="ecoutes" :showDetail="false"/>
                                        </div>
                                    </div>
                                </div>
                          </b-tab>
                          <b-tab>
                                <template v-slot:title>
                                  <span class="d-inline-block d-sm-none">
                                    <!-- <i class="far fa-envelope"></i> -->
                                    Transactions
                                  </span>
                                  <span class="d-none d-sm-inline-block">Transactions <span class="badge fs-6" :class="{ 'bg-primary': true }"> {{ transactions.length }} </span> </span>
                      
                                </template>
                                <div class="row">
                                    <div class="card">
                                        <div class="card-body">
                                            <Transaction :transactions="transactions" :showDetail="false"/>
                                        </div>
                                    </div>
                                </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                      <!-- end card-body -->
                    </div>
            </div>
    </Layout>
</template>